import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Home from "./Home";
import Footer from "../components/Footer";
import { Modal, IconButton } from "@mui/material";
import Image from "../assets/Home/Image.jpg";
import CloseIcon from "@mui/icons-material/Close";
import "../styles/Home.css";

const Landing = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Navbar />
      <Modal open={open} onClose={handleClose}>
        <div className="landing-modal">
          <img
            src={Image}
            alt="Image"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
          <IconButton
            onClick={handleClose}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Modal>
      <Home />
      <Footer />
    </>
  );
};

export default Landing;
