import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import Table from "./components/Table";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const nav = useNavigate();
  useEffect(() => {
    const user = sessionStorage.getItem("user");
    const data = JSON.parse(user);
    if (user === "" || user === null || data != "admin") {
      nav("/admin");
    }
  });

  return (
    <>
      <Navbar />
      <Table />
    </>
  );
};

export default Dashboard;
