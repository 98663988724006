import * as React from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import {
  Box,
  Button,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Menu,
  Container,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles/Header.css";

const navLinks = [
  { text: "About Us", location: "#section2" },
  { text: "Workshop", location: "/workshopform" },
  { text: "Services", location: "#section6" },
  { text: "Countries", location: "#section5" },
  { text: "Contact Us", location: "#section7" },
];

const ResponsiveAppBar = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 250 : 400,
    bgcolor: "background.paper",
    border: "2px solid #006D34",
    borderRadius: "8px",
    boxShadow: 24,
    p: isMobile ? 2 : 5,
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar className="NavBar">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                mt: 1,
                textDecoration: "none",
              }}
            >
              <h3 className="full-logo">
                <span className="logo">GRi</span>
                <span className="logo-text">
                  Global Resources international
                </span>
              </h3>
            </Typography>
            {/* Mobile View */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none", justifyContent: "end" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  width: "200px",
                  color: "#61DE86",
                }}
              >
                {navLinks.map((linkInfo, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <NavLink className="nav-links" to={linkInfo.location}>
                      <div className="link" style={{ color: "#C36100" }}>
                        {linkInfo.text}
                      </div>
                    </NavLink>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* Desktop View */}
            <Box
              sx={{
                flexGrow: 2,
                justifyContent: "end",
                display: { xs: "none", md: "flex" },
              }}
            >
              {navLinks.map((linkInfo, index) => (
                <Button
                  key={index}
                  onClick={handleCloseNavMenu}
                  sx={{
                    display: "block",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <NavLink className="nav-links" to={linkInfo.location}>
                    <div className="link">{linkInfo.text}</div>
                  </NavLink>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default ResponsiveAppBar;
