import React, { useState, useEffect } from "react";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { useNavigate } from "react-router-dom";
import { Grid, Alert } from "@mui/material";
import G1 from "../../assets/Home/Image.jpg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { postData } from "../../services/Request";
import "../../styles/WorkShopForm.css";

const FlutterPayment = () => {
  const nav = useNavigate();
  const [userData, setUserData] = useState([]);
  const [status, setStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const generateTxRef = () => {
    const timestamp = Date.now().toString();
    return `TX-${timestamp}`;
  };

  const txref = generateTxRef();

  const config = {
    public_key: "FLWPUBK_TEST-457d64d18d0840bbec072269ae0273b6-X",
    tx_ref: txref,
    amount: 70,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: userData.email,
      phone_number: userData.phoneNumber,
      name: userData.firstName + " " + userData.lastName,
    },
    customizations: {
      title: "Teach USA",
      description: "Payment for workshop",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const fwConfig = {
    ...config,
    text: "Pay Now",
    callback: (response) => {
      if (response.status == "successful") {
        setStatus(true);
        setPaymentData(response);
        const UserData = {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          age: userData.age,
          gender: userData.gender,
          phoneNumber: userData.phoneNumber,
          maritalStatus: userData.maritalStatus,
          kids: userData.kids,
          address: userData.address,
          qualifications: userData.qualifications,
          criteria: userData.criteria,
          awardingInstitution: userData.awardingInstitution,
          certifications: userData.certifications,
          occupation: userData.occupation,
          subjectTeach: userData.subjectTeach,
          gradeTeach: userData.gradeTeach,
          travelInternationally: userData.travelInternationally,
          employed: userData.employed,
          countryList: userData.countryList,
          aboutWorkshop: userData.aboutWorkshop,
          hearWorkshop: userData.hearWorkshop,
          selectDate: userData.selectDate,
          amount: response.amount,
          currency: response.currency,
          transaction_id: response.transaction_id,
          tx_ref: response.tx_ref,
        };
        postData("user/add", UserData)
          .then((response) => {
            if (response.status === true) {
              setAlertMessage("User Add");
              setSeverity("success");
            } else {
              setAlertMessage("Error User Not Add");
              setSeverity("error");
            }
          })
          .catch((error) => {
            setAlertMessage("Error in Adding");
            setSeverity("error");
          });
      }
      // console.log(response);
      closePaymentModal();
    },
    onClose: () => {},
  };

  const handleClick = () => {
    nav("/workshopform");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6} className="hide">
        <img src={G1} alt="Global" style={{ width: "100%", height: "98vh" }} />
      </Grid>
      <Grid item xs={12} lg={6}>
        {alertMessage && (
          <Alert variant="filled" severity={severity}>
            {alertMessage}
          </Alert>
        )}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              <ArrowBackIosIcon />
              <span style={{ fontSize: "26px" }}>Back</span>
            </div>
          </Grid>
          <Grid item xs={11} lg={8} mt={4}>
            <span className="user-data">Full Name:</span>
            <span className="user-info">
              {userData.firstName + " " + userData.lastName}
            </span>
          </Grid>
          <Grid item xs={11} lg={8} mt={2}>
            <span className="user-data">Email: </span>
            <span className="user-info">{userData.email}</span>
          </Grid>
          <Grid item xs={11} lg={8} mt={2}>
            <span className="user-data">Contact: </span>
            <span className="user-info">{userData.phoneNumber}</span>
          </Grid>
          <Grid item xs={6} mt={5}>
            {status == false ? (
              <FlutterWaveButton {...fwConfig} className="payment-btn" />
            ) : (
              <div className="reg">Registered Successfully</div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FlutterPayment;
