import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../../services/Request";
import {
  Container,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Navbar from "./components/Navbar";

const ShowUser = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const { qualifications = [] } = userData;

  useEffect(() => {
    getData(`user/${id}`)
      .then((response) => {
        if (response.status === true) {
          setUserData(response.user);
        }
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <Navbar />
      <Container>
        <Grid container spacing={2} justifyContent="center">
          {/* First Grid (Labels) */}
          <Grid item xs={12} mt={1}>
            <div className="form-heading">User Info</div>

            {/* Full Name */}
            <Grid container spacing={2} mt={2}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">Full Name</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.firstName + " " + userData.lastName}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Email */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">Email</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.email}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Gender */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">Gender</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.gender}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Date of Birth */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">Age</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.age}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Phone Number */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="label-text">Phone Number</Typography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  value={userData.phoneNumber}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                />
              </Grid>
            </Grid>
            {/* Current Resident */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">Marital Status</Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  value={userData.maritalStatus}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Country */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">Address</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.address}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/*Educational Qualifications  */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="label-text">
                  Educational Qualifications (Select all the educational
                  qualifications you have)
                </Typography>
              </Grid>

              <Grid item lg={6} xs={12}>
                {userData.qualifications && (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={userData.EducationQualification === "Bachelor"}
                          checked={userData.qualifications.includes("Diploma")}
                        />
                      }
                      label="Diploma"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.qualifications.includes(
                            "Bachelors Degree"
                          )}
                        />
                      }
                      label="Bachelors Degree"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.qualifications.includes(
                            "Masters Degree"
                          )}
                        />
                      }
                      label="Masters Degree"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.qualifications.includes(
                            "Doctorate /Phd"
                          )}
                        />
                      }
                      label="Doctorate /Phd"
                    />
                  </FormGroup>
                )}
              </Grid>
            </Grid>
            {/* Criteria */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  Check As Many Of The Listed Criteria Below That You Meet.
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                {userData.criteria && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "Bachelors Degree"
                          )}
                        />
                      }
                      label="Bachelors Degree"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "Bachelors Degree in Education"
                          )}
                        />
                      }
                      label="Bachelors Degree in Education"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "Teachers License In Home Country"
                          )}
                        />
                      }
                      label="Teachers License In Home Country"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "Over 2 Years Teaching Experience"
                          )}
                        />
                      }
                      label="Over 2 Years Teaching Experience"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "English Proficiency Test Certificate"
                          )}
                        />
                      }
                      label="English Proficiency Test Certificate"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "Educational Credential Assesment Evaluation"
                          )}
                        />
                      }
                      label="Educational Credential Assesment Evaluation"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "Background Check Clearance"
                          )}
                        />
                      }
                      label="Background Check Clearance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userData.criteria.includes(
                            "Verifiable References"
                          )}
                        />
                      }
                      label="Verifiable References"
                    />
                  </FormGroup>
                )}
              </Grid>
            </Grid>
            {/* Bacholer Institution */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  List All Your Educational Qualifications : Name Of Awarding
                  Institution & Year Obtained : For Example: Bsc Education in
                  Mathematics - University Of XYZ _ 2020
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.awardingInstitution}
                  fullWidth
                  multiline
                  rows={4}
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/*Current Occuption  */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  List any Certifications or Membership of Professional
                  Associations
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.certifications}
                  fullWidth
                  multiline
                  rows={4}
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/*Teaching License Country */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  Current Occupation (if not currently employed state
                  'unemployed')
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.occupation}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Teaching License Issuer */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  What Subject Do You Currently Teach
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.subjectTeach}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/*Teaching License Status  */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  If Teacher What Grade (Age Level) Do You Currently Teach
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.gradeTeach}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/*Teaching Experience*/}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  Have You Travelled Internationally Before?
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.travelInternationally}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Subject Grade */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  If Employed : 1. Current Employer / School Name 2. Job Title
                  3. How Long You Have Been Employed There 4. Annual Salary in
                  Local Currency
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  value={userData.employed}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",

                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Current Teaching */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  If you have travelled internationally - list country, date ,
                  type of visa received and duration of stay
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.countryList}
                  fullWidth
                  multiline
                  rows={4}
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",

                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* timeCurrentJob */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  What are you hoping to learn from the Workshop.
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.aboutWorkshop}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Subject Age Level */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  What Subject & Grade /Age Level Are You Currently Teaching?
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.CurrentlyTeachGrade}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Type Of School Teaching*/}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  What Type Of School Are You Currently Teaching At? e.g.
                  Public. Govt, Private, Christian, International School
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  value={userData.TypeOfSchool}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      height: "36px",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Interested In Role */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  Why Are You Interested In Getting A Teaching Role In The USA
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  value={userData.InsterestedRole}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/*International Passport*/}
            {/* <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="label-text">
                  Do you have an International Passport
                </Typography>
              </Grid>

              <Grid item lg={6} xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userData.InternationalPassport === "Yes"}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userData.InternationalPassport === "No"}
                      />
                    }
                    label="No"
                  />
                </FormGroup>
              </Grid>
            </Grid> */}
            {/* visitOtherCountry */}
            {/* <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  Which Countries Have You Visited or Lived In Other Than Your
                  Country Of Residence
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  multiline
                  rows={2}
                  value={userData.CountryVisit}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",
                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid> */}
            {/* Contact Method */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="label-text">
                  Preffered Method of Contact
                </Typography>
              </Grid>

              <Grid item lg={6} xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox checked={userData.ContactMethod === "Phone"} />
                    }
                    label="Phone"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={userData.ContactMethod === "Email"} />
                    }
                    label="Email"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            {/*platformName  */}
            <Grid container spacing={2} mt={1}>
              <Grid item lg={6} xs={12}>
                <Typography className="lable-text">
                  How Did You Hear About This Program (if through someone ,
                  their name , if online, name of Platform
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  multiline
                  rows={2}
                  value={userData.HearAboutProgram}
                  fullWidth
                  InputProps={{
                    style: {
                      backgroundColor: "#F2E6E6",

                      border: "1px solid #F2E6E6",
                      borderRadius: "5px",
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>

          {/* Second Grid (Input Fields) */}
        </Grid>
      </Container>
    </>
  );
};

export default ShowUser;
