import React from "react";
import { Grid } from "@mui/material";
import "./styles/Footer.css";
import Location from "../assets/Footer/location.png";
import Mail from "../assets/Footer/mail.png";
import Phone from "../assets/Footer/phone.png";
import Social from "../assets/Footer/social.png";

const Footer = () => {
  return (
    <>
      <Grid container spacing={2} mt={2} style={{ backgroundColor: "#C36100" }} justifyContent='center'>
        <Grid item xs={11} lg={5}>
          <div style={{ marginLeft: "10px", marginTop: "20px" }}>
            <h3 className="full-logo">
              <span className="logo">GRi</span>
              <span className="logo-text">Global Resources international</span>
            </h3>
          </div>
        </Grid>
        <Grid item xs={11} lg={5}>
          <div className="footer">
            <div className="link">
              <span className="link-img">
                <img src={Location} alt="location"></img>
              </span>
              4140 Parker Rd. Allentown, New Mexico 31134
            </div>
            <div className="link">
              <span className="link-img">
                <img src={Mail} alt="email"></img>
              </span>
              wavepalm@gmail.com
            </div>
            <div className="link">
              <span className="link-img">
                <img src={Phone} alt="phone"></img>
              </span>
              (205) 555-0100
            </div>
            <div className="social-link">
              <span className="">
                <img src={Social} alt="social"></img>
              </span>
            </div>
          </div>
        </Grid>
        <Grid container className="center-container">
          <Grid item xs={10} className="center-text">
            © 2024. All rights reserved.
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
