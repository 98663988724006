import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../styles/Home.css";
import { useNavigate, useLocation } from "react-router-dom";
import Img1 from "../assets/Home/img1.jpg";
import img1 from "../assets/Home/1.jpg";
import img2 from "../assets/Home/2.jpg";
import img3 from "../assets/Home/3.jpg";
import img4 from "../assets/Home/4.jpg";
import int1 from "../assets/Home/int1.jpg";
import int2 from "../assets/Home/int2.jpg";
import int3 from "../assets/Home/int3.jpg";
import Plane from "../assets/Home/Plane.jpg";
import Flag1 from "../assets/Home/usa.jpg";
import Flag2 from "../assets/Home/europe.jpg";
import Flag3 from "../assets/Home/canada.jpg";
import T1 from "../assets/Home/T1.jpg";
import T2 from "../assets/Home/T2.jpg";
import T3 from "../assets/Home/T3.jpg";
import T4 from "../assets/Home/T4.jpg";

const Home = () => {
  const nav = useNavigate();
  const location = useLocation();
  const images = [
    { src: img1, title: "Image 1" },
    { src: img2, title: "Image 2" },
    { src: img3, title: "Image 3" },
    { src: img4, title: "Image 4" },
  ];
  useEffect(() => {
    const sectionId = location.hash.slice(1);
    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  const intro = [
    { src: int1, title: "Group Consultation or One on One" },
    {
      src: int2,
      title: "Well detailed information customised to your need and benefit",
    },
    {
      src: int3,
      title:
        "Saves you time and money embarking on a journey that may not beneficial.",
    },
  ];

  const country = [
    { src: Flag1, title: "Image 1" },
    { src: Flag2, title: "Image 2" },
    { src: Flag3, title: "Image 3" },
  ];

  const Teachers = [
    { src: T1, title: "Cultural adaptability", tag: "Make Payment" },
    { src: T2, title: "Etiquette", tag: "Make Payment" },
    { src: T3, title: "⁠Employability Skills", tag: "Make Payment" },
    { src: T4, title: "⁠Communication Skills", tag: "Make Payment" },
  ];

  const handdleClick = () => {
    nav("/workshopform");
  };
  return (
    <>
      {/* Section 1 */}
      <Grid container spacing={2} justifyContent="center" id="section1" mt={5}>
        <Grid item xs={10} mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                justifyContent="center"
                style={{ position: "relative" }}
              >
                <Box
                  position="absolute"
                  top="15px"
                  left="35px"
                  width="20vh"
                  height="20vh"
                  border={"1px solid #FFB46B"}
                  zIndex={-1}
                />

                <Box
                  position="absolute"
                  bottom="15px"
                  right="30px"
                  width="20vh"
                  height="20vh"
                  border={"1px solid #FFB46B"}
                  zIndex={-1}
                />
                <Grid item xs={11} padding={5}>
                  <img
                    src={Img1}
                    alt="img1"
                    style={{ marginLeft: "1%", width: "100%" }}
                  ></img>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} padding={5} mt={1}>
              <div className="home-heading-1">The Teach in the USA Program</div>
              <div className="home-text-1">
                Global Relocation Service and Training is a comprehensive
                solution that helps individuals and organizations with the
                process of relocating to a new country or location. Whether it's
                for personal reasons or business purposes, this service provides
                assistance in various areas such as immigration, housing,
                transportation, and cultural integration if required.
                <br />
                <br />
                The relocation service aspect of this offering centers on aiding
                individuals and families in navigating the intricacies and
                opportunities associated with moving to a different country.
                This assistance may encompass support with visa and immigration
                procedures, as well as identifying suitable housing options. The
                overarching objective is to facilitate a seamless transition by
                providing comprehensive support and guidance throughout the
                entire relocation process.
                <br />
                <br />
                Conversely, the training component of Global Relocation Service
                and Training is tailored to assist individuals and companies in
                acclimating to their new surroundings. This includes cultural
                training to foster an understanding of the customs and norms of
                the destination country, language classes to enhance
                communication capabilities, and professional development
                programs aimed at enabling individuals to thrive in their new
                professional environment. The training aspect endeavors to equip
                individuals with the requisite skills and knowledge necessary
                for success in their new location.
              </div>
              <div>
                {/* <Button
                  variant="contained"
                  onClick={handdleClick}
                  sx={{
                    background: `linear-gradient(to right, #FFB46B, #A17447 )`,
                    paddingBottom: 2,
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 10,
                    marginTop: 4,
                  }}
                >
                  Book Now
                </Button> */}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Section 2 Services*/}
      <div id="section2"></div>
      <div className="section-2">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={10}>
            <div className="service-title">We Provide Different Services</div>
          </Grid>
          <Grid item xs={10} lg={10}>
            <div className="service-description">
              The relocation service aspect of this offering focuses on helping
              individuals and families navigate the complexities and
              opportunities available with moving to a different country. This
              may include assistance with visa and immigration procedures,
              finding suitable housing options, The goal is to make the
              transition as smooth as possible by providing support and guidance
              throughout the entire process.
              <br />
              <br />
              On the other hand, the training component of Global Relocation
              Service and Training is designed to help individuals and companies
              adapt to their new environment. This may involve cultural training
              to understand the customs and norms of the destination country,
              language classes to facilitate communication, and professional
              development programs to help individuals excel in their new work
              environment. The training aspect aims to equip individuals with
              the necessary skills and knowledge to succeed in their new
              location.
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={10} lg={10}>
            <Grid container spacing={2} mt={2}>
              {images.map((image, index) => (
                <Grid item xs={6} lg={3} key={index}>
                  <img
                    src={image.src}
                    alt={image.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={handdleClick}
          className="button-bookNow"
        >
          Book Now
        </Button>
      </div>

      {/* Section 3 */}
      <div id="section3"></div>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ backgroundColor: "#FFFAF5" }}
        pb={3}
      >
        <Grid item xs={11}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Accordion style={{ borderLeft: "4px solid #C36100" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Phase 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="card">
                      <div className="card-heading">Phase 1</div>
                      <ol>
                        <li className="fw-6"> Client Consultation</li>
                        <ul>
                          <li>Client Information</li>
                          <li>⁠Information Evaluation</li>
                        </ul>
                      </ol>
                      <button className="card-button">Pay Now</button>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion style={{ borderLeft: "4px solid #C36100" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Phase 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="card">
                      <div className="card-heading">Phase 2</div>
                      <div className="card-subheading">Level 1</div>
                      <ol>
                        <li className="fw-6">
                          Identify Client eligibility and opportunities.
                        </li>
                        <li className="fw-6">Opportunity Preparation</li>
                        <ul>
                          <li>
                            Skills /Credentials Review , Assessment &
                            Recommendations
                          </li>
                          <li>Documentation Requirements Review</li>
                          <li>Resume Review & Revision</li>
                          <li>Cover Letter Creation</li>
                        </ul>
                        <li className="fw-6">Profile Creation </li>
                        <ul>
                          <li>Application Preparation</li>
                        </ul>
                      </ol>
                      <button className="card-button">Pay Now</button>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion style={{ borderLeft: "4px solid #C36100" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Phase 2 (Level 2)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="card">
                      <div className="card-heading">Phase 2</div>
                      <div className="card-subheading">Level 2</div>
                      <ol>
                        <li className="fw-6">
                          Application Preparation & Submission
                        </li>
                        <ul>
                          <li>ob Search & Submissions</li>
                          <li>Programs Application Registration</li>
                        </ul>
                        <li className="fw-6">Profile Creation</li>
                        <ul>
                          <li>Application Preparation</li>
                        </ul>
                      </ol>
                      <button className="card-button">Pay Now</button>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion style={{ borderLeft: "4px solid #C36100" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Phase 2 (Level 3)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="card">
                      <div className="card-heading">Phase 2</div>
                      <div className="card-subheading">Level 3</div>
                      <ol>
                        <li className="fw-6">Interview Prep</li>
                      </ol>
                      <button className="card-button">Pay Now</button>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion style={{ borderLeft: "4px solid #C36100" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Employer phase</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="card">
                      <div className="card-heading">Employer phase</div>
                      <div className="card-subheading">
                        Application Process and Response Follow Up
                      </div>
                      <button className="card-button">Pay Now</button>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*section 4 */}
      <div id="section4"></div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div className="intro-heading">
            Introduction on Relocation and Registration
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} textAlign="center">
              <img src={Plane} alt="img" className="plane-img"></img>
            </Grid>
            <Grid item xs={12} lg={6}>
              {intro.map((image, index) => (
                <div className="int-box">
                  <div>
                    <span className="int-img">
                      <img
                        src={image.src}
                        alt={image.title}
                        style={{
                          height: "69px",
                          width: "74px",
                          marginTop: "15px",
                          marginLeft: "10px",
                          borderRadius: "5px",
                        }}
                      />
                    </span>
                    <span className="int-tag">{image.title}</span>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Section 5  countries*/}
      <div id="section5"></div>
      <div className="section-2">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={10}>
            {" "}
            <div className="service-title">Countries</div>
          </Grid>
          <Grid item xs={10}>
            <div className="service-description">
              Overall, Global Relocation Service and Training is a comprehensive
              solution that offers support in both the logistical and cultural
              aspects of relocation. Whether you are an individual or a company,
              this service can help make the transition to a new country or
              location smoother and more successful.
            </div>
          </Grid>
          <Grid item xs={10} lg={6}>
            <Grid container spacing={4} mt={2}>
              {country.map((image, index) => (
                <Grid item xs={4} lg={4} key={index}>
                  <img
                    src={image.src}
                    alt={image.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={handdleClick}
          className="button-bookNow"
        >
          Book Now
        </Button>
      </div>

      {/* Section 6 */}
      <div id="section6"></div>
      <Grid container justifyContent="center" mt={5} mb={5}>
        <Grid item xs={10}>
          <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12} className="course-heading">
              Mandatory International Teachers Preparatory Course
            </Grid>
            <Grid xs={12} mt={2} sx={{ textAlign: "center" }}>
              4 weeks course
            </Grid>

            {Teachers.map((image, index) => (
              <Grid item lg={6} xs={12} mt={1}>
                <div className="course-box">
                  <div>
                    <div className="course-img ">
                      <img
                        src={image.src}
                        alt={image.title}
                        className="img-size"
                      />
                    </div>
                    <div className="course-tag">
                      <div className="img-title">{image.title}</div>
                      <div className="img-tag">{image.tag}</div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <div id="section7"></div>
    </>
  );
};

export default Home;
