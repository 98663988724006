import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  Typography,
  Select,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import G1 from "../assets/Form/Global.jpg";
import P1 from "../assets/Home/Image.jpg";
import "../styles/WorkShopForm.css";

const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  age: "",
  gender: "",
  phoneNumber: "",
  maritalStatus: "",
  kids: "",
  address: "",
  qualifications: [],
  criteria: [],
  awardingInstitution: "",
  certifications: "",
  occupation: "",
  subjectTeach: "",
  gradeTeach: "",
  travelInternationally: "",
  employed: "",
  countryList: "",
  aboutWorkshop: "",
  hearWorkshop: "",
  selectDate: "",
};

const WorkShopForm = () => {
  const nav = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("userData");
    if (storedUserData) {
      setFormData(JSON.parse(storedUserData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem("userData", JSON.stringify(formData));
    nav("/payment");
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ backgroundColor: "#f3f3fe" }}
      >
        <Grid item xs={12} lg={8} sx={{ backgroundColor: "#ffff" }}>
          <div className="img-1">
            <img src={G1} alt="1" style={{ width: "100%" }}></img>
          </div>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ backgroundColor: "#ffff" }} mt={4}>
          <Grid container spacing={2} justifyContent="center" mb={4} mt={2}>
            <Grid item xs={11}>
              <div className="heading">TEACH USA 2024</div>
              <div className="subheading">WORKSHOP REGISTRATION FORM</div>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} justifyContent="center" mt={2}>
            <Grid item xs={11}>
              <div className="text-1">
                Please note that Global Careers Consulting is not an immigration
                law firm or provide immigration services. We do not provide jobs
                or offer any guarantee of job placement.
                <br />
                <br />
                We provide GLOBAL & INTERNATIONAL CAREER SERVICES and related
                services to assist you with pursuing your career goals, and
                provide information on career opportunities in various
                countries.
                <br />
                <br />
                We offer a variety of Services including 1:1 Career Consulting
                and Career Coaching ; Group Workshops and other related Career
                Services.
                <br />
                <br />
                <span className="highlight">
                  *Service Fees will apply for various components of our
                  services.
                </span>
              </div>
              <div className="img-2">
                <img src={P1} alt="2" style={{ width: "100%" }}></img>
              </div>
              <div className="text-2">
                The Teach USA 2024 Workshop is a Career Services Workshop for
                overseas teachers who are interested in learning about Teaching
                Oportunties in the USA. All attendees must meet all of the
                eligibilty criteria listed above (Bachelors Degree not in
                Education may be eliglible to participate in some programs if in
                a subect area that Teacher will teach).
                <br />
                <br />
                <span className="highlight">
                  The Workshop will provide you with:
                  <br />
                  <br />
                  1. Infomation about the Programs that offer Teaching
                  Opportunites in the USA for overseas teachers including
                  details about Application timeline, procedure, required
                  documentation, fees etc.
                  <br />
                  <br />
                  2. Career Development Tools , Strategies and Support to help
                  you prepare an effective and impressive Career Portfolio.
                  <br />
                  <br />
                  3.Sample Templates to assist you with your Career Portfolio.
                  <br />
                  <br />
                  4. Information on the "Next Steps" for those who want to
                  proceed with Applying to the Program Organisations
                  <br />
                  <br />
                  5. Q& A Session where you can ask questions.
                  <br />
                  <br />
                  ** Guest Presenter from Program or Hiring Organization may be
                  in attendance at some workshops**
                  <br />
                  <br />
                  6. Details for scheduling follow up 1: 1 Consultation
                  sessions.
                </span>
                <br />
                <br />
                <span className="text-3">
                  Note that this workshop is a career workshop for informational
                  purposes only and does not constitute a recruiment or offer of
                  employment.
                  <br />
                  All programs and employment opportunities and offers of
                  employment are made by the hiring organization or educational
                  establishment.
                </span>
                <br />
                <br />
                <span className="text-4">
                  The Teach USA 2024 Workshop is a Virtual Workshop on the
                  specified date and time. Select the Date and Time you are
                  registering for below. Only registered participant will be
                  given access to join.
                </span>
                <br />
                <br />
                <span className="text-5">
                  Workshop Registration is not complete until Form has been
                  processed and workshop fee payment received. Participant will
                  receive confirmation via email when Workshop Registration has
                  been completed.
                </span>
                <br />
                <br />
                <span className="text-3">
                  WORKSHOP FEES ARE NON -REFUNDABLE. PLEASE ENSURE THAT YOU HAVE
                  MET ALL THE PREREQUISITE CRITERIA LISTED IN ABOVE FLYER BEFORE
                  PAYMENT OF FEES.
                </span>
                <br />
                <br />
                <span className="text-6">
                  If you decide to complete this form you attest that you have
                  Read, Understand and Agree to the Terms and Conditions and the
                  scope of our services any and all other implied terms and
                  conditions.
                </span>
              </div>

              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} mt={3} mb={3}>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      First Name <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.firstName}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="text"
                      name="firstName"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      Last Name <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.lastName}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="text"
                      name="lastName"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      E-mail <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="email"
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      Age <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.age}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="text"
                      name="age"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl>
                      <FormLabel className="label">
                        Gender <span className="red">*</span>
                      </FormLabel>
                      <RadioGroup
                        name="gender"
                        required
                        value={formData.gender}
                        onChange={handleInputChange}
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      Conatct No <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      name="phoneNumber"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      Marital Status <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.maritalStatus}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="text"
                      name="maritalStatus"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      No of kids Under 21 <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.kids}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      name="kids"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      City & Country of Current Residence{" "}
                      <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.address}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="text"
                      name="address"
                    />
                  </Grid>
                  {/* Qulification */}
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      Degree Qualifications (select as many as applicable)
                      <span className="red">*</span>
                    </FormLabel>
                    <FormGroup required>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="qualifications"
                            value="Diploma"
                            checked={formData.qualifications.includes(
                              "Diploma"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Diploma"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="qualifications"
                            value="Bachelors Degree"
                            checked={formData.qualifications.includes(
                              "Bachelors Degree"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Bachelors Degree"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="qualifications"
                            value="Masters Degree"
                            checked={formData.qualifications.includes(
                              "Masters Degree"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Masters Degree"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="qualifications"
                            value="Doctorate /Phd"
                            checked={formData.qualifications.includes(
                              "Doctorate /Phd"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Doctorate /Phd"
                      />
                    </FormGroup>
                  </Grid>
                  {/* Criteria */}
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      Check As Many Of The Listed Criteria Below That You Meet.
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="Bachelors Degree"
                            checked={formData.criteria.includes(
                              "Bachelors Degree"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Bachelors Degree"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="Bachelors Degree in Education"
                            checked={formData.criteria.includes(
                              "Bachelors Degree in Education"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Bachelors Degree in Education"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="Teachers License In Home Country"
                            checked={formData.criteria.includes(
                              "Teachers License In Home Country"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Teachers License In Home Country"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="Over 2 Years Teaching Experience"
                            checked={formData.criteria.includes(
                              "Over 2 Years Teaching Experience"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Over 2 Years Teaching Experience"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="English Proficiency Test Certificate"
                            checked={formData.criteria.includes(
                              "English Proficiency Test Certificate"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="English Proficiency Test Certificate"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="Educational Credential Assesment Evaluation"
                            checked={formData.criteria.includes(
                              "Educational Credential Assesment Evaluation"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Educational Credential Assesment Evaluation"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="Background Check Clearance"
                            checked={formData.criteria.includes(
                              "Background Check Clearance"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Background Check Clearance"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="criteria"
                            value="Verifiable References"
                            checked={formData.criteria.includes(
                              "Verifiable References"
                            )}
                            onChange={handleInputChange}
                          />
                        }
                        label="Verifiable References"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      List All Your Educational Qualifications : Name Of
                      Awarding Institution & Year Obtained : For Example: Bsc
                      Education in Mathematics - University Of XYZ _ 2020
                      <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.awardingInstitution}
                      onChange={handleInputChange}
                      type="text"
                      name="awardingInstitution"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      List any Certifications or Membership of Professional
                      Associations<span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.certifications}
                      onChange={handleInputChange}
                      type="text"
                      name="certifications"
                    />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormLabel className="label">
                      Current Occupation (if not currently employed state
                      'unemployed')<span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.occupation}
                      onChange={handleInputChange}
                      type="text"
                      name="occupation"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      What Subject Do You Currently Teach{" "}
                      <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.subjectTeach}
                      onChange={handleInputChange}
                      type="text"
                      name="subjectTeach"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      If Teacher What Grade (Age Level) Do You Currently Teach{" "}
                      <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.gradeTeach}
                      onChange={handleInputChange}
                      type="text"
                      name="gradeTeach"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      Have You Travelled Internationally Before?
                      <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.travelInternationally}
                      onChange={handleInputChange}
                      type="text"
                      name="travelInternationally"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      If Employed : 1. Current Employer / School Name 2. Job
                      Title 3. How Long You Have Been Employed There 4. Annual
                      Salary in Local Currency
                    </FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.employed}
                      onChange={handleInputChange}
                      type="text"
                      name="employed"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      If you have travelled internationally - list country, date
                      , type of visa received and duration of stay
                    </FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.countryList}
                      onChange={handleInputChange}
                      type="text"
                      name="countryList"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      What are you hoping to learn from the Workshop.
                    </FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.aboutWorkshop}
                      onChange={handleInputChange}
                      type="text"
                      name="aboutWorkshop"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormLabel className="label">
                      How did you hear about our Company and the Workshop?
                      <span className="red">*</span>
                    </FormLabel>
                    <TextField
                      fullWidth
                      required
                      value={formData.hearWorkshop}
                      onChange={handleInputChange}
                      type="text"
                      name="hearWorkshop"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel className="label">
                      Select Workshop Date Below : Workshop Fee : $70
                      <span className="red">*</span>
                    </FormLabel>
                    <div className="container">
                      <div className="box">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox disabled checked />}
                          />
                        </FormGroup>
                      </div>
                      <div className="box">
                        <Box
                          sx={{
                            width: "100px",
                            height: "100px",
                            border: "1px solid black",
                          }}
                        >
                          <img
                            src={P1}
                            alt="P1"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </div>
                      <div className="box" style={{ width: "100%" }}>
                        <Typography>
                          Teach USA 2024 Workshop Fee $70.00
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <Typography>Upcoming Workshop Dates</Typography>
                          <Select
                            name="selectDate"
                            fullWidth
                            required
                            value={formData.selectDate}
                            onChange={handleInputChange}
                          >
                            <MenuItem value="Sunday , February 18, 2024 : 3 p.m. GMT +1">
                              Sunday , February 18, 2024: 3 p.m. GMT +1
                            </MenuItem>
                            <MenuItem value="Saturday , February 24, 2024 : 3 p.m. GMT +1">
                              Saturday , February 24, 2024 : 3 p.m. GMT +1
                            </MenuItem>
                          </Select>
                        </Box>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25px",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    //   disabled={loder == true}
                    sx={{
                      background: `linear-gradient(to right, #FFB46B, #A17447 )`,
                      paddingBottom: 2,
                      paddingTop: 2,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 10,
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkShopForm;
