import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  styled,
  Stack,
  Checkbox,
  Grid,
  Button,
} from "@mui/material";
import Edit from "../../../assets/Admin/Edit.png";
import Delete from "../../../assets/Admin/Delete.png";
import Red from "../../../assets/Admin/Red.png";
import Green from "../../../assets/Admin/Green.png";
import Swal from "sweetalert2";
import "../style/Style.css";
import { getData, postData } from "../../../services/Request";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

const rowsPerPageOptions = [10, 20, 30];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontWeight: "600",
  color: "#C36100",
}));

const UserTable = () => {
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    getData("user/all")
      .then((response) => {
        if (response.status === true) {
          setUserData(response.model);
        }
      })
      .catch((error) => {});
    setSelectedRowsCount(selectedRows.length);
  }, [selectedRows]);

  const handleToggleAllCheckboxes = (event) => {
    const isChecked = event.target.checked;
    const updatedUserData = userData.map((user) => ({ ...user, isChecked }));
    setUserData(updatedUserData);
    setSelectedRows(isChecked ? updatedUserData.map((user) => user._id) : []);
  };

  const handleToggleCheckbox = (_id) => {
    const selectedIndex = selectedRows.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const handleDeleteSelectedRows = () => {
    Swal.fire({
      title: "Do you want to delete the selected rows?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // setUserData(
        //   userData.filter((user) => !selectedRows.includes(user._id))
        // );
        postData("user/deleteSelected", selectedRows);
        window.location.reload();

        Swal.fire("Deleted!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Rows not deleted", "", "info");
      }
    });
  };

  const handleCancelSelection = () => {
    setUserData(userData.map((user) => ({ ...user, isChecked: false })));
    setSelectedRows([]);
  };

  // Count of selected rows
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (_id) => {
    Swal.fire({
      title: "Do you want to delete this person?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // setUserData(userData.filter((user) => user._id !== _id));
        postData("user/delete/" + _id);
        Swal.fire("Deleted!", "", "success");
        window.location.reload();
      } else if (result.isDenied) {
        Swal.fire("Person not deleted", "", "info");
      }
    });
  };

  const handleEdit = (index) => {
    // const updatedUserData = [...userData];
    // updatedUserData[index].isEditing = !updatedUserData[index].isEditing;
    // setUserData(updatedUserData);
  };

  return (
    <>
      <Grid container spacing={2} mt={3} justifyContent="center">
        <Grid item xs={11}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="table-heading">
              Candidate Information
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleCancelSelection}
                className="Button1"
                sx={{
                  paddingBottom: 2,
                  paddingTop: 2,
                  paddingLeft: 4,
                  paddingRight: 4,
                  borderRadius: 10,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteSelectedRows}
                disabled={selectedRows.length === 0}
                className="Button1"
                sx={{
                  marginLeft: "10px",
                  paddingBottom: 2,
                  paddingTop: 2,
                  paddingLeft: 4,
                  paddingRight: 4,
                  borderRadius: 10,
                }}
              >
                Delete ({selectedRowsCount})
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ background: "#FAE8D6" }}>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            onChange={handleToggleAllCheckboxes}
                            checked={selectedRows.length === userData.length}
                            indeterminate={
                              selectedRows.length > 0 &&
                              selectedRows.length < userData.length
                            }
                          />
                        </TableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Phone Number</StyledTableCell>
                        <StyledTableCell>Select Date</StyledTableCell>
                        <StyledTableCell>Payment </StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={index}
                            className={`row-height ${
                              index % 2 === 0 ? "odd-row" : "even-row"
                            }`}
                          >
                            <TableCell>
                              <Checkbox
                                checked={selectedRows.includes(row._id)}
                                onChange={() => handleToggleCheckbox(row._id)}
                              />
                            </TableCell>
                            <TableCell>
                              {row.firstName + " " + row.lastName}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.phoneNumber}</TableCell>
                            <TableCell>
                              {/* {row.formStatus ? "Submitted" : "Not Submitted"} */}
                              {row.selectDate}
                            </TableCell>
                            <TableCell>
                              {row.amount == 70 ? (
                                <div className="paid">
                                  <img
                                    src={Green}
                                    alt="."
                                    style={{ marginRight: "4px" }}
                                  ></img>
                                  Paid
                                </div>
                              ) : (
                                <div className="unpaid">
                                  <img
                                    src={Red}
                                    alt="."
                                    style={{ marginRight: "4px" }}
                                  ></img>
                                  Unpaid
                                </div>
                              )}

                              {/* {row.payment ? "Paid" : "Unpaid"} */}
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={2}>
                                <div
                                  onClick={() => handleEdit(row._id)}
                                  className="cu"
                                >
                                  <img src={Edit} alt="edit"></img>
                                </div>
                                <div
                                  onClick={() => handleDelete(row._id)}
                                  className="cu"
                                >
                                  <img src={Delete} alt="delete"></img>
                                </div>
                                <div className="cu">
                                  <Link to={`/show-user/${row._id}`}>
                                    <VisibilityIcon sx={{ color: "#F90000" }} />
                                  </Link>
                                </div>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={userData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserTable;
