import Axios from "axios";
// const baseUrl = "http://localhost:3000/api/";
const baseUrl = "https://education-bc22bec3188a.herokuapp.com/api/";

export var postData = (route, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(baseUrl + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getData = (route, data) => {
  return new Promise((resolve, reject) => {
    fetch(baseUrl + route, {
      method: "GET",
      headers: {},
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.error("error in post api", error);
        reject(error);
      });
  });
};
