import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { postData } from "../../services/Request";
import CircularProgress from "@mui/material/CircularProgress";

export default function AdminLogIn() {
  const nav = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loader, setLoder] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var details = {
      Username: e.target.user.value,
      Password: e.target.password.value,
    };
    if (details.Username.trim() === "") {
      setAlertMessage("Input your Username");
      setSeverity("warning");
    } else if (details.Password.trim() === "") {
      setAlertMessage("Enter your Passwords");
      setSeverity("warning");
    } else {
      setLoder(true);
      setAlertMessage("");
      postData("admin/login", details)
        .then((response) => {
          setLoder(false);
          if (response.status === true) {
            sessionStorage.setItem("user", JSON.stringify(response.user));
            setAlertMessage("Logging you in......");
            setSeverity("success");
            nav("/dashboard");
          } else {
            setAlertMessage("Error logging you in");
            setSeverity("error");
          }
        })
        .catch((error) => {
          setLoder(false);
          setAlertMessage(
            "Error logging you in, please check your account is activated"
          );
          setSeverity("error");
        });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        {alertMessage && (
          <Alert variant="filled" severity={severity}>
            {alertMessage}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "32px", fontWeight: "700" }}>
            Welcome Back
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="User"
              label="User Name"
              name="user"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            {loader === false && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Log In
              </Button>
            )}
            {loader === true && (
              <Button fullWidth variant="outlined">
                <CircularProgress />
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
}
