import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const settings = ["Logout"];

const Navbar = () => {
  const nav = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState("Admin");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    nav("/admin");
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#C36100",
          boxShadow: "none",
          marginBottom: "5px",
          height: "80px",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/dashboard"
              sx={{
                mr: 2,
                mt: 1,
                // display: { xs: "none", md: "flex" },
                textDecoration: "none",
              }}
            >
              <h3 className="full-logo">
                <span className="logo">GRi</span>
                <span className="logo-text">
                  Global Resources international
                </span>
              </h3>
            </Typography>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/dashboard"
              sx={{
                mr: 2,
                mt: 1,
                display: { xs: "flex", md: "none" },
                textDecoration: "none",
                flexGrow: 1,
              }}
            ></Typography>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                display: { xs: "none", md: "flex" },
              }}
            ></Box>
            <Box
              sx={{
                flexGrow: 0,
                backgroundColor: "white",
                padding: "8px 18px 8px 18px",
                borderRadius: "26px",
              }}
            >
              <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt={userInfo} src="/" />
                  {userInfo}
                  <ExpandMoreIcon
                    sx={{ color: "black" }}
                    onClick={handleOpenUserMenu}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      handleCloseUserMenu();
                      handleLogout();
                    }}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default Navbar;
