import { Route, Routes } from "react-router-dom";
// import TeacherForm from "./pages/teacher/Form";
import Landing from "./pages/Landing";
import Dashboard from "./pages/admin/Dashboard";
// import TestPage from "./pages/payment/Test";
import AdminLogIn from "./pages/admin/AdminLogin";
import ShowUser from "./pages/admin/ShowUser";
import WorkShopForm from "./pages/WorkShopForm";
import Flutterwave from "./pages/payment/Flutterwave";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/workshopform" element={<WorkShopForm />}></Route>
        <Route path="/payment" element={<Flutterwave />}></Route>

        {/* Teacher */}
        {/* <Route path="/teacherform" element={<TeacherForm />}></Route> */}

        {/* Admin */}
        <Route path="/admin" element={<AdminLogIn />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/show-user/:id" element={<ShowUser />}></Route>
        {/* <Route path="/test" element={<TestPage />}></Route> */}
      </Routes>
    </div>
  );
}

export default App;
